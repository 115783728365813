import Gallery from "../../../../src/components/gallery.jsx";
import Embed from "../../../../src/components/embed.jsx";
import voorlater1 from "../../../../src/static/img/work/voorlater/voorlater-9.png";
import voorlater2 from "../../../../src/static/img/work/voorlater/voorlater-2.png";
import voorlater3 from "../../../../src/static/img/work/voorlater/voorlater-3.png";
import voorlater4 from "../../../../src/static/img/work/voorlater/voorlater-11.png";
import React from 'react';
export default {
  Gallery,
  Embed,
  voorlater1,
  voorlater2,
  voorlater3,
  voorlater4,
  React
};