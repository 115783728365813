module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultQuality":100,"maxWidth":4000,"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":4000,"defaultQuality":100,"withWebp":true}}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Richard Space","short_name":"Richard Space","start_url":"/","background_color":"#F6F6F6","theme_color":"#D2D7EA","display":"minimal-ui","icon":"src/static/trademarks/favicon.svg"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
