import Gallery from "../../../../src/components/gallery.jsx";
import Embed from "../../../../src/components/embed.jsx";
import Container from "../../../../src/components/container.jsx";
import sc5 from "../../../../src/static/img/work/squaresAndCircles/sc1.png";
import sc1 from "../../../../src/static/img/work/squaresAndCircles/gif/1.gif";
import sc2 from "../../../../src/static/img/work/squaresAndCircles/gif/2.gif";
import sc3 from "../../../../src/static/img/work/squaresAndCircles/gif/3.gif";
import sc4 from "../../../../src/static/img/work/squaresAndCircles/gif/4.gif";
import React from 'react';
export default {
  Gallery,
  Embed,
  Container,
  sc5,
  sc1,
  sc2,
  sc3,
  sc4,
  React
};