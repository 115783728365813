import Gallery from "../../../../src/components/gallery.jsx";
import Embed from "../../../../src/components/embed.jsx";
import test1 from "../../../../src/static/img/work/radioDabanga/prep/test-1.jpg";
import gif1 from "../../../../src/static/img/work/radioDabanga/dabanga-1.gif";
import db1 from "../../../../src/static/img/work/radioDabanga/dabanga-3.png";
import db2 from "../../../../src/static/img/work/radioDabanga/dabanga-2.png";
import db3 from "../../../../src/static/img/work/radioDabanga/dabanga-4.png";
import React from 'react';
export default {
  Gallery,
  Embed,
  test1,
  gif1,
  db1,
  db2,
  db3,
  React
};