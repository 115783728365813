import Gallery from "../../../../src/components/gallery.jsx";
import Embed from "../../../../src/components/embed.jsx";
import Container from "../../../../src/components/container.jsx";
import img1 from "../../../../src/static/img/work/photogaphy/Analog Photos/analog-photo-16.jpg";
import img2 from "../../../../src/static/img/work/photogaphy/Analog Photos/analog-photo-18.jpg";
import img3 from "../../../../src/static/img/work/photogaphy/Analog Photos/analog-photo-10.jpg";
import img4 from "../../../../src/static/img/work/photogaphy/Analog Photos/analog-photo-20.jpg";
import img5 from "../../../../src/static/img/work/photogaphy/Analog Photos/analog-photo-6.jpg";
import img6 from "../../../../src/static/img/work/photogaphy/Analog Photos/analog-photo-8.jpg";
import img7 from "../../../../src/static/img/work/photogaphy/Analog Photos/analog-photo-24.jpg";
import img8 from "../../../../src/static/img/work/photogaphy/Analog Photos/analog-photo.jpg";
import img9 from "../../../../src/static/img/work/photogaphy/Analog Photos/analog-photo-2.jpg";
import React from 'react';
export default {
  Gallery,
  Embed,
  Container,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  React
};