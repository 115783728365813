import Gallery from "../../../../src/components/gallery.jsx";
import Embed from "../../../../src/components/embed.jsx";
import hvhh1 from "../../../../src/static/img/work/hvhh-trailer/hvhh-1.png";
import hvhh2 from "../../../../src/static/img/work/hvhh-trailer/hvhh-2.png";
import hvhh3 from "../../../../src/static/img/work/hvhh-trailer/hvhh-3.png";
import hvhh4 from "../../../../src/static/img/work/hvhh-trailer/hvhh-4.png";
import React from 'react';
export default {
  Gallery,
  Embed,
  hvhh1,
  hvhh2,
  hvhh3,
  hvhh4,
  React
};